import * as React from "react"

import Layout from '../components/layout'
import PlainPage from "../components/plainPage"
import Seo from '../components/seo'


export default function NotFoundPage() {
  return (
    <Layout>
      <Seo title='Sara Fashionwear' />
      <PlainPage headline='Page not found' subline='404 error' body='Sorry, we couldn’t find the page you’re looking for.'/>
    </Layout>
  )
}