import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function PlainPage({headline, subline, body}) {
  return <div className="bg-white min-h-screen flex flex-col lg:relative -mt-24">
  <div className="flex-grow flex flex-col">
    <main className="flex-grow flex flex-col bg-white">
      <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 my-auto py-16 sm:py-32">
          <p className="text-sm font-semibold text-primary-900 uppercase tracking-wide">{subline}</p>
          <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            {headline}
          </h1>
          <p className="mt-2 text-base text-gray-700">{body}</p>
          <div className="mt-6">
            <Link to="/" className="bg-primary-300 hover:bg-primary-500 py-1 rounded-sm px-2 text-base font-medium text-gray-700">
              Go back home<span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    </main>
    <footer className="flex-shrink-0 bg-gray-50">
      <div className="mx-auto max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
        <nav className="flex space-x-4">
          <Link to="/contact" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Contact Us
          </Link>
          <span className="inline-block border-l border-gray-300" aria-hidden="true" />
          <a href="https://www.linkedin.com/company/69508045/" target="_blank" rel="noreferrer" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            LinkedIn
          </a>
        </nav>
      </div>
    </footer>
  </div>
  <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-2/5">
    <StaticImage
      className="absolute inset-0 h-full w-full object-cover"
      objectPosition={'top'}
      src="../images/404.jpg"
      alt="Models posing"
    />
  </div>
</div>;
}
